import React from "react";
import Conclusion from "../../components/project/Conclusion";
import CommonProjectComponent from "../../components/project/CommonProjectComponent";
import ProblemSolution from "../../components/project/ProblemSolution";
import ProjectDetailBanner from "../../components/project/ProjectDetailBanner";
import PreviousNext from "../../components/PreviousNext";
import { Seo } from "../../components/seo";
import Layout from "../../components/layout";

const PayInvoice = () => {
  const headingbanner = {
    title: `Pay Invoice`,
    content: `An AI-based comprehensive P2P solution`,
  };

  const data = {
    images: ["pay-invoice.webp", "pay-invoice-2.webp"],
    firstAlt: "Login Screen For P2P Platform",
    firstTitle: "Login Screen",
    secondAlt: "UI UX Dashboard to check the compliance status",
    secondTitle: "Dashboard",
  };

  const problemSolutionContent = {
    sidebar: [
      {
        title: "Industry",
        text: ["FinTech"],
      },
      {
        title: "What we did",
        text: ["Branding", "UI UX Designing", "Wordpress Development"],
      },
      {
        title: "Platform",
        text: ["Web Application"],
      },
    ],
    mainContent: [
      {
        title: "Problem",
        content: [
          `There was a problem to gain a deeper understanding of GST laws and filling processes to simplify the process in the user interface which required a smart grouping of data points comprehensively. `,
          `Additionally, we also had to understand the mindsets of both administrators and users to design relatable and user-friendly screens that cater to their respective tasks. `,
          `We had to design an informative dashboard that displays all ongoing transactions, due payments, overall billings, and payables.`,
          `Furthermore, our design should also provide information and actionable options for vendors, GST compliance and purchase orders, which should ultimately improve overall efficiency.`,
        ],
      },
      {
        title: "Solution",
        content: [
          `Our redesign of the Pay Invoice buyer portal, supplier portal, and invoice processing unit platforms aimed to provide an intuitive dashboard that incorporates all relevant information related to GST compliance ratings, MSME compliance, ITC risk factor, billings, and payables. Additionally, the inclusion of AI-generated insights helped improve overall efficiency.`,
          `We also prioritized the ease of adding new vendors and offered the capability to view vendor details based on different GSTINs.`,
          `Furthermore, we also facilitated the management of vendor performance and credits. The platform also enables the efficient handling of vendor-wise purchase orders, invoices, and payments. `,
          `Overall, our solution streamlined the invoicing and payment processes and also enhanced visibility and control for both buyers and suppliers.`,
        ],
      },
    ],
    image: ["pay-invoice-problem.webp"],
  };

  const projectData = {
    title: "Login Screen",
    para: [
      `We created a login screen that requires users to enter their credentials before accessing the website. It offers several benefits such as improved security, user experience, and data protection.`,
    ],
    content: {
      image: ["pay-invoice-projectone.webp"],
    },
  };

  const projectData2 = {
    title: `Dashboard`,
    para: [
      `We also created a dashboard to assist users understand their compliance risk and ITC Reconciliation Status visually. We also added a bot for better user assistance. `,
    ],
    content: {
      image: ["pay-invoice-projecttwo.webp"],
    },
  };

  const projectData3 = {
    title: "Vendor Overview",
    para: [
      `This screen shows individual vendor details on their compliance and also offers an overview report on their purchase orders and invoices. `,
    ],
    content: {
      image: [
        "pay-invoice-projectthree.webp",
        "pay-invoice-projectthree-2.webp",
        "pay-invoice-projectthree-3.webp",
        "pay-invoice-projectthree-4.webp",
        "pay-invoice-projectthree-5.webp",
      ],
    },
  };

  const conclusionData = [
    {
      para: [
        `In conclusion, our comprehensive redesign of the Pay Invoice buyer portal, supplier portal, and invoice processing unit platforms successfully addressed the complex challenges of understanding and simplifying GST laws and filling processes. `,
        `Our solution significantly improved overall efficiency by designing an informative dashboard that incorporates all relevant information. `,
        `Overall, our solution streamlined invoicing and payment processes while providing a user-friendly experience for administrators and users alike.`,
      ],
    },
  ];

  return (
    <Layout>
      <div className="service-page">
        <ProjectDetailBanner data={data} headingbanner={headingbanner} />
        <ProblemSolution data={problemSolutionContent} />
        <CommonProjectComponent data={projectData} mode="cream" />
        <CommonProjectComponent data={projectData2} />
        <CommonProjectComponent data={projectData3} mode="dark" />
        <Conclusion conclusionData={conclusionData} />
        <PreviousNext currentProjectId={21} />
      </div>
    </Layout>
  );
};

export default PayInvoice;

export const Head = () => (
  <Seo title="Pay Invoice - Octet Design Studio" description="We provided UI UX design services, web app frontend development to AI-based invoicing and P2P platform Pay Invoice in the Fintech sector." />
)